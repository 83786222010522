import * as React from "react";
import Slider from "react-slick";
import { Button, Grid, Typography } from "@mui/material";
import placeHolder from "../../assets/img/placeholder.png";
import qrCode from "../../assets/img/qrCode.png";
import sephoraLogo from "../../assets/img/sephoraLogo.png";
import "./styles.scss";
import { PowredByTyme } from "..";
import { useSelector } from "react-redux";
import { RootState } from "../../store/rootReducer";
import withStyles from "@mui/styles/withStyles";
import { config } from "../../config/config";
import { useTranslation } from "react-i18next";
import Dialog from "@mui/material/Dialog";
import CardMedia from "../CardMedia";
import miniLogo from "../../assets/img/tyme_mini.png";
import backgroundPlaceholder from "../../assets/img/background.png";
import makeStyles from "@mui/styles/makeStyles";
import { DEFAULT_LABEL_COLOR_SLIDER } from "../../utils/constants";
interface SliderProps {
	hideSlider: () => void;
}

export default function SimpleSlider(props: SliderProps) {
	const { t } = useTranslation();
	const { hideSlider } = props;
	const menuState = useSelector((state: RootState) => state.menu);
	const colors = menuState?.colors;
	const merchant = menuState?.merchant;

	const pictures: any = [
		{ image: merchant.photo1, title: "I-1" },
		{ image: merchant.photo2, title: "I-2" },
		{ image: merchant.photo3, title: "I-3" },
		{ image: merchant.photo1, title: "I-1" },
		{ image: merchant.photo2, title: "I-2" },
		{ image: merchant.photo3, title: "I-3" }
	];
	const useStyles = makeStyles(theme => ({
		slider: {
			[theme.breakpoints.up("lg")]: {
				marginTop: "6%"
			},
			[theme.breakpoints.down(350)]: {
				maxHeight: 150,
				maxWidth: 150
			}
		},
		container: {
			width: "25%",
			[theme.breakpoints.down(1500)]: {
				width: "40%"
			},
			[theme.breakpoints.down(900)]: {
				width: "50%"
			},
			[theme.breakpoints.down(750)]: {
				width: "90%"
			},
		}
	}));
  const classes = useStyles();
	const StyledButton = withStyles({
		root: {
			backgroundColor: colors[1],
			color: colors[0],
			border: '1px solid' + colors[0],
			width: '80%',
			fontSize: "24pt",
			"&:hover": {
				backgroundColor: colors[0],
				color: colors[1]
			}
		}
	})(Button);

	var settings = {
		dots: false,
		speed: 2000,
		infinite: true,
		autoplay: true,
		autoplaySpeed: 4000
	};

	return (
		<Dialog open={true} onClose={() => {}} fullWidth id="slider-dialog">
			<Slider {...settings}>
				{pictures.map((picture: any) => (
					<div key={picture.title}>
						<CardMedia
							style={{
								height: "105vh",
								alignItems: "center",
								justifyContent: "center",
								display: "flex"
							}}
							image={picture.image ? picture.image : placeHolder}
							placeHolder={backgroundPlaceholder}
						/>
					</div>
				))}
			</Slider>
			<Grid
				container
				justifyContent="center"
				alignItems="center"
				direction="column"
				style={{
					height: "100%",
					position: "absolute",
					background: colors[1],
					marginLeft: "5%",
					opacity: "0.85",
					justifyContent: "space-around"
				}}
				className={classes.container}
			>
				<div style={{ marginTop: "5%" }}>
					<CardMedia
						style={{
							maxHeight: 250,
							maxWidth: 250,
							objectFit: "contain",
							border: colors[1]
						}}
						image={
							merchant.logo
								? config.BASE_URL_API + merchant.logo
								: placeHolder
						}
						placeHolder={miniLogo}
						className={classes.slider}
					/>
				</div>
				<div>
					<Typography style={{ color: DEFAULT_LABEL_COLOR_SLIDER }}>
						{t("common.click_here_to")}
					</Typography>
				</div>
				<div
					style={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "center"
					}}
				>
					<StyledButton onClick={() => hideSlider()}>
						{t("common.click_to_order")}
					</StyledButton>
				</div>
				<div>
					<CardMedia
						style={{
							height: 250,
							width: 250,
							objectFit: "contain"
						}}
						image={qrCode}
						placeHolder={miniLogo}
						className={classes.slider}
					/>
				</div>
				<div style={{ marginBottom: "3%" }}>
					<PowredByTyme />
				</div>
			</Grid>
		</Dialog>
	);
}
